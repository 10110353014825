<template>
  <b-overlay :show="loading" rounded="sm" no-fade class="mt-1">
    <b-card v-if="settingsData">
      <b-row class="mb-1">
        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">General Settings</th>
            </tr>
          </thead>
        </table>
      </b-row>

      <validation-observer ref="settingsRules">
        <b-form>
          <b-row>
            <b-col cols="12" sm="6" md="6" xl="6">
              <b-form-group label="Reminder email to enter PO Number (in days)" label-for="poNumberDay">
                <validation-provider name="input" #default="{ errors }" rules="required">
                  <b-form-input id="poNumberDay" type="text" v-model="settingsData.poNumberDay"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="6" xl="6">
              <b-form-group :label="'Number of days to close the project after the reminder is sent'" label-for="poNumberReminderDay">
                <validation-provider name="input" #default="{ errors }" rules="required">
                  <b-form-input id="poNumberReminderDay" type="text" v-model="settingsData.poNumberReminderDay"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="6" xl="6">
              <b-form-group label="Number of days to send the survey after the project is completed" label-for="surveyDay">
                <validation-provider name="input" #default="{ errors }" rules="required">
                  <b-form-input id="surveyDay" type="text" v-model="settingsData.surveyDay"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="6" xl="6">
              <b-form-group label="Internal Labor Rate" label-for="internalRate">
                <div class="d-flex">
                  <b-form-input id="internalRate" type="number" v-model="internalRate"></b-form-input>
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="6" xl="6">
              <b-form-group label="External Labor Rate" label-for="externalRate">
                <div class="d-flex">
                  <b-form-input id="externalRate" type="number" v-model="externalRate"></b-form-input>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mb-1">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th scope="col">Email Settings</th>
                </tr>
              </thead>
            </table>
          </b-row>

          <b-row>
            <b-col cols="12" sm="6" md="4" xl="4">
              <b-form-group label="Report Emails" label-for="reportEmails">
                <div class="d-flex">
                  <b-form-input id="reportEmails" type="email" @keyup.enter="updateReportEmailList" v-model="reportEmail"></b-form-input>
                  <b-button type="button" @click="updateReportEmailList" variant="outline-primary">
                    <i class="fa-solid fa-plus"></i>
                  </b-button>
                </div>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="4">
              <b-form-group label="Accountant Emails" label-for="accountantEmails">
                <div class="d-flex">
                  <b-form-input id="accountantEmails" type="email" @keyup.enter="updateAccountantEmailList" v-model="accountantEmail"></b-form-input>
                  <b-button type="button" @click="updateAccountantEmailList" variant="outline-primary">
                    <i class="fa-solid fa-plus"></i>
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="6" md="4" xl="4">
              <b-form-group label="Notification Emails" label-for="notificationEmails">
                <div class="d-flex">
                  <b-form-input id="notificationEmails" type="email" @keyup.enter="updateNotificationEmailList" v-model="notificationEmail"></b-form-input>
                  <b-button type="button" @click="updateNotificationEmailList" variant="outline-primary">
                    <i class="fa-solid fa-plus"></i>
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" sm="6" md="4" xl="4">
              <strong>Report Email List:</strong>
              <hr />
              <ul class="email-list">
                <li v-for="(email, index) in reportEmailList" :key="index">
                  <span>{{ email }}</span>
                  <b-button @click="removeReportEmail(index)" variant="primary" size="sm"><i class="fa-regular fa-trash-can"></i></b-button>
                </li>
              </ul>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="4">
              <strong>Accountant Email List:</strong>
              <hr />
              <ul class="email-list">
                <li v-for="(email, index) in accountantEmailList" :key="index">
                  <span>{{ email }}</span>
                  <b-button @click="removeAccountantEmail(index)" variant="primary" size="sm"><i class="fa-regular fa-trash-can"></i></b-button>
                </li>
              </ul>
            </b-col>

            <b-col cols="12" sm="6" md="4" xl="4">
              <strong>Notification Email List:</strong>
              <hr />
              <ul class="email-list">
                <li v-for="(email, index) in notificationEmailList" :key="index">
                  <span>{{ email }}</span>
                  <b-button @click="removeNotificationEmail(index)" variant="primary" size="sm"><i class="fa-regular fa-trash-can"></i></b-button>
                </li>
              </ul>
            </b-col>

            <b-col cols="12">
              <b-button type="button" @click="formSubmitted" variant="primary" class="float-right">
                Save Changes
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { onUnmounted } from '@vue/composition-api';
import settingsStoreModule from '../settingsStoreModule';
import vSelect from 'vue-select';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from '@validations';

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    const DEPARTMENT_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(DEPARTMENT_APP_STORE_MODULE_NAME)) store.registerModule(DEPARTMENT_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DEPARTMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(DEPARTMENT_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      loading: true,
      settingsData: null,
      reportEmail: '',
      accountantEmail: '',
      reportEmailList: [],
      accountantEmailList: [],
      internalRate: 0,
      externalRate: 0,
      notificationEmail: '',
      notificationEmailList: [],
    };
  },

  watch: {},

  methods: {
    formSubmitted() {
      this.$refs.settingsRules.validate().then((success) => {
        if (success) {
          this.loading = true;

          if (this.settingsData != null) {
            this.settingsData.reportMails = this.reportEmailList;
            this.settingsData.accountantMails = this.accountantEmailList;
            this.settingsData.notificationMails = this.notificationEmailList;
            this.settingsData.internalRate = this.internalRate;
            this.settingsData.externalRate = this.externalRate;

            store
              .dispatch('settings/updateSettings', this.settingsData)
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ Settings Update Successful',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });

                this.getSettings();
              })
              .catch((error) => {
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
              });
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                text: 'Please fill in all fields.',
                icon: 'AlertTriangleIcon',
                variant: 'warning',
              },
            });

            this.loading = false;
          }
        }
      });
    },

    getSettings() {
      this.loading = true;

      store
        .dispatch('settings/getSettings')
        .then((response) => {
          this.settingsData = response.data;
          this.reportEmailList = response.data.reportMails;
          this.accountantEmailList = response.data.accountantMails;
          this.notificationEmailList = response.data.notificationMails;
          this.internalRate = response.data.internalRate;
          this.externalRate = response.data.externalRate;

          this.loading = false;
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          });

          this.$router.push({ name: 'settings-dashboard' });
        });
    },

    updateReportEmailList() {
      if (this.isValidEmail(this.reportEmail)) {
        if (this.reportEmail) {
          this.reportEmailList.push(this.reportEmail);
          this.reportEmail = '';
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Invalid Email Address',
            text: 'Please enter a valid email address.',
            icon: 'MailIcon',
            variant: 'danger',
          },
        });
      }
    },

    updateAccountantEmailList() {
      if (this.isValidEmail(this.accountantEmail)) {
        if (this.accountantEmail) {
          this.accountantEmailList.push(this.accountantEmail);
          this.accountantEmail = '';
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Invalid Email Address',
            text: 'Please enter a valid email address.',
            icon: 'MailIcon',
            variant: 'danger',
          },
        });
      }
    },

    updateNotificationEmailList() {
      if (this.isValidEmail(this.notificationEmail)) {
        if (this.notificationEmail) {
          this.notificationEmailList.push(this.notificationEmail);
          this.notificationEmail = '';
        }
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Invalid Email Address',
            text: 'Please enter a valid email address.',
            icon: 'MailIcon',
            variant: 'danger',
          },
        });
      }
    },

    removeReportEmail(index) {
      this.reportEmailList.splice(index, 1);
    },

    removeAccountantEmail(index) {
      this.accountantEmailList.splice(index, 1);
    },
    removeNotificationEmail(index) {
      this.notificationEmailList.splice(index, 1);
    },

    isValidEmail(email) {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      return emailRegex.test(email);
    },
  },

  created() {
    this.getSettings();
  },

  computed: {},
};
</script>

<style scoped>
.email-list {
  list-style: none;
  padding: 0;
}

.email-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.email-list li:hover {
  background-color: #ffffff50;
  cursor: pointer;
}

.email-list b-button {
  flex-shrink: 0;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
